import React from "react"
import { Card } from "react-bootstrap"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
const commissions = () => {
  return (
    <Layout>
      <Helmet>
        <title>Commissions - Pamela Mary Brown</title>
      </Helmet>
      <h2>Commissions</h2>
      <Card style={{ border: "none", backgroundColor: "transparent" }}>
        <Card.Title style={{ marginTop: "10px" }}>
          O City, City on the Foyle
        </Card.Title>
        <Card.Text style={{ marginBottom: "10px" }}>
          This was a commission to write a public poem for the Derry City and
          Strabane Heritage Council. Spoken Word Poem and Voiceover.
        </Card.Text>
      </Card>
      <div className="video-style">
        <iframe
          width="100%"
          height="350"
          src="https://www.youtube.com/embed/40oxaSmW35A"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <hr style={{ background: "black" }} />
      <Card style={{ border: "none", backgroundColor: "transparent" }}>
        <Card.Title style={{ marginTop: "10px" }}>
          2020 A Taste of LegenDerry Advert
        </Card.Title>
        <Card.Text style={{ marginBottom: "10px" }}>
          Writer: Spoken Word Poem.
        </Card.Text>
      </Card>
      <div className="video-style">
        <iframe
          width="100%"
          height="350"
          src="https://www.youtube.com/embed/bUrPCw9kbrI"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </Layout>
  )
}

export default commissions
